<template>
  <div style="width: 100%">

    <div class="back-img">
      <div style="font-size: 50px; color: #fff">REGISTRATION</div>
    </div>

    <div class="register-zone">

      <div class="reg-text">
        
        <div style="margin-bottom: 20px">
          Registration for iSPEC2021 online is now open. Register your place today to enjoy two full days of technical presentations and discussions. 
        </div>
        <div style="margin-bottom: 20px">
          iSPEC2021 offers both on-site and web conference participation options. To be included in the program, each paper must have one on-site registration completed by one of the authors. A full registration will just cover <a>ONE</a> paper.
        </div>

       <div style="margin-bottom: 35px">
          <a>Papers MUST be registered in order to be included into the Conference Proceedings that is to be submitted to the IEEE Xplore.</a>
       </div>

        <div class="important-dates">
         <div font-weight: bold>Deadline for Registration</div> 
         <div>Author: October 31, 2021</div>
         <div>Non-Author: November 15, 2021</div>
        </div>
      </div>

      <div class="reg-btn">
        <img :src="require('assets/images/registration/btn.png')" alt="" @click="register">
      </div>

    </div>

    <div class="fee-tables">
      <div class="reg-fee">
        Registration Rates
      </div>
      <div>
        <a>On-site Registration</a> (Authors and representatives from non-risk regions) 
      </div>
      
      <div>
        <img :src="require('assets/images/registration/table1.png')" alt="" />
      </div>

      <div>
        <a>Web Registration</a> (Only for international delegates and non-author representatives from medium or high risk regions in China)
      </div>

      <div>
        <img :src="require('assets/images/registration/table2.png')" alt="">
      </div>
     
      <a>
        On-site Fees Include
      </a>
      
      <div>
        On-site fees include attendance at conference all activities, full access to the exhibition, lunch buffet during the conference, tea break, delegate name badge, material bag, conference USB disk, etc. 
      </div>
      <br/>
      <a>
       Web Registration Fees Include
      </a>
      <div>
        Web registration fees include access to all panels and sessions online, and a download link for conference proceedings. 
      </div>

      <div class="reg-fee">
       Payment Method
      </div>
      <a> Bank Transfer </a>
      <div>Account Name: Chinese Society for Electrical Engineering（中国电机工程学会）</div>
      <div>Account Number: 020 0000 6290 0340 5059</div>
      <div>Deposit Bank: ICBC Beijing Yingtaoyuan Branch（工行北京市分行樱桃园支行）</div>
      <div>Statement Reference: iSPEC+Name+Registration Number <a>(Required fields, e.g., RCA0001) </a> </div>
      <div>Or you can pay online via <a> Alipay </a> .</div>
      
      <div class="reg-fee">
       Note to Authors
      </div>
      <div>Please note each paper must have at least one author registered on-site by <a> Sunday, 31 October 2021</a>. Papers that have not completed online registration and paid the registration fee after the deadline cannot be included in the conference proceedings and cannot be entered into the EI. Authors must reconfirm the method of paper publication (orally or by poster) when registering online. </div>
    <br/>
     <div class="area-notes">
        <div>Downloads :
          <a v-for="quicklink in quickLinkData" :href="quicklink.link">
                {{quicklink.text}}
          </a>
        </div>
     </div>
    </div>

  </div>
  
</template>

<script>
export default {
  name: "Registration",
  methods:{
    register(){
      window.location.href = "http://action.csee.org.cn:8080/ICEE/a/login?redLoginUrl=/a/paper/goPaperindex?activityId=243"   
    }
  },
  data(){
    return{
        quickLinkData:[
        {
          text:'Conference Notice',
          link: process.env.BASE_URL+ 'conference notice.pdf',
        },
      ]
    }

  }

}
</script>

<style scoped lang="scss">
.back-img{
  background-image: url("../assets/images/registration/bgc1.png");
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-zone{
  border-top: 1px solid white;
  background-image: url("../assets/images/registration/bgc2.png");
  height: 448px;
  margin:  100px 0;

  .reg-btn{
    margin: 50px 0 10px 0;
    display: flex;
    justify-content: center;

    img{
      cursor: pointer;
    }
  }

  .reg-text{
    margin: 30px 200px;
    color: #fff;
    font-size: 18px;
    font-family: PingFang;
    font-weight: lighter;
    line-height: 20px;


    .important-dates{
    text-align: center;
    font-size: 24px;
    font-family: FZYDZHJW;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 40px;
    }
  }
  a{
      color:#FFFFFF;
      text-decoration: none;
      font-weight: 600;    
  }
}

.fee-tables{
  margin: 50px 200px;
  font-size: 18px;
  font-family: PingFang;
  font-weight: 400;
  color: #666666;
  line-height: 25px;

  img{
    margin: 30px 0;
  }

  .reg-fee{
    font-size: 24px;
    font-family: FZYDZHJW;
    font-weight: normal;
    color: #222222;
    line-height: 10px;
    margin: 30px 0;
    font-weight: 700;
  }

  .reg-note{
    font-size: 20px;
    font-family: FZYDZHJW;
    font-weight: normal;
    color: #666666;
    line-height: 10px;
    margin: 30px 0;

  }
  a{
      color:#494949;
      text-decoration: none;
      font-weight: 600;    
  }

}
.area-notes{
   margin-bottom: 30px;
    &>div{
      font-size: 24px;
      color: #000;
      font-family: Microsoft YaHei;
      
      line-height: 24px;

      a{
        text-decoration: none;
        color: #11A3CA;
      }
    }

}
</style>
